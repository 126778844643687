<!-- <div style="background-color: #0d6efd;">
  <p class="ribbon" style="margin-top: 10px;margin-left: 10px;margin-right: 10px;margin-bottom: 10px;">
      <span class="text"><strong class="bold">Poster:</strong> Create customized Poster</span>
  </p>
  </div> -->

  <div class="row">
    <!-- <img src="../../assets/images/Header2.jpg" style="width: 1500px; height: 200px;"> -->
    <div class="col-sm-12">
      <img src="../../assets/images/Header2.jpg" width="100%">
    </div>
    
  </div>
  
  <hr/>
  
  <div class="container-fluid">
      <div class="row" style="margin-bottom: 2%;"></div>
      <form #userForm="ngForm" (ngSubmit)=onSubmit(userForm)>
          <div class="row">
            
            <div class="col-sm-5" >
              <div class="row">
                <div class="col-sm-6">               
                  <img src="../../assets/images/BELogo.jpg">

                </div>
                <div class="col-sm-6">
                  <img src="../../assets/images/CCDLogo.jpg"> 

                </div>
              </div>
            </div>

              <div class="col-sm-7" >
                  <section class="fieldset">
                      <h1>Enter Details</h1>
                      <p></p>
                      <div class="form-group">
                          <input type="text" name="drName" ngModel class="form-control" maxlength="25" placeholder="Name">
                      </div>
                      <br />
  
                      <!-- <div class="form-group">
                          <input type="text" name="clinicName" ngModel class="form-control" maxlength="25" placeholder="Clinic Name">
                      </div>
                      <br /> -->
  
                      <div class="form-group">
                          <input type="text" name="place" ngModel class="form-control" placeholder="Place" maxlength="25">
                      </div>
                      <br />
  
                      <!-- <div class="form-group">
                          <input type="number" name="mobile" ngModel class="form-control" placeholder="Mobile" maxlength="10">
                      </div> -->
                  </section>
                  <section class="fieldset">
                    <h1>Upload Image</h1>
                    <p></p>
                    <div>
                        <label>Please Upload an Image    -   </label>
                        <input type="file" (change)="fileChangeEvent($event)"/>
                    </div>
                    <p></p>
                    <section id="secCropImg" class="fieldset">
                        <h1>Crop Image</h1>
                        <p></p>
                        <div class="row">
                            <div class="col-md-8">
                                <image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true"
                                            [aspectRatio]="4 / 4" [resizeToWidth]="256" format="png"
                                            (imageCropped)="imageCropped($event)" (imageLoaded)="imageLoaded()"
                                            (cropperReady)="cropperReady()" (loadImageFailed)="loadImageFailed()"></image-cropper>
                            </div>
                            <div class="col-md-4">
                                <label id="lblPreview"></label>
                                <img [src]="croppedImage" style="border-radius: 50%" />
                            </div>
                        </div>
                        

                    </section>
                </section>
              </div>
          </div>
          <div class="row"><p></p><hr/></div>
          <div class="row">
            <div class="row" style="text-align: center;">
              <!-- <div class="col-sm-4"></div>
              <div class="col-sm-4"><h5>Please Select a Template</h5></div>
              <div class="col-sm-4"></div> -->
              <h5>Template</h5>
              <hr/>
            </div>
            <div class="row">
              <div class="col-sm-4">

              </div>
              <div class="col-sm-4" style="padding-left: 5%;">
                <img id="canvas5" class="img-responsive" src="../../assets/images/canvas5.jpg" (click)="onImgSelected('canvas5')" style="margin: 2%;" width="300px" height="400px">
              </div>
              <div class="col-sm-4">

              </div>
              <!-- <div class="col-sm-3">
                <img id="canvas1" src="../../assets/images/canvas1.jpg" (click)="onImgSelected('canvas1')" style="margin: 10%;" width="200px" height="300px">
              </div>
              <div class="col-sm-3">
                <img id="canvas2" src="../../assets/images/canvas2.jpg" (click)="onImgSelected('canvas2')" style="margin: 10%;" width="200px" height="300px">
              </div>
              <div class="col-sm-3">
                <img id="canvas3" src="../../assets/images/canvas3.jpg" (click)="onImgSelected('canvas3')" style="margin: 10%;" width="200px" height="300px">
              </div>
              <div class="col-sm-3">
                <img id="canvas4" src="../../assets/images/canvas4.jpg" (click)="onImgSelected('canvas4')" style="margin: 10%;" width="200px" height="300px">
              </div> -->
            </div>
          </div>
          <div class="row"><p></p><hr/></div>
          <div class="row">
              <div class="col-md-4"></div>
              <div class="col-md-4">
                  <!-- <button class="btn btn-primary" (click)="clearData()">Clear All</button> &nbsp; -->
                  <button class="btn btn-primary" type="submit">Generate Poster</button> &nbsp;
                  <button class="btn btn-primary" id="download" (click)="download()">Download Poster</button>
              </div>
              <div class="col-md-4"></div>
          </div>
          <div class="row"><p></p><hr/></div>
      </form>
      <div class="row" style="margin-bottom: 2%;">
      <hr/>
      </div>
      <div class="row">
          <!-- <div class="col-sm-3"></div> -->
          <div class="col-sm-12">
              <canvas style="align-content: center;" id="canvas" width="700" height="800"></canvas>
          </div>
          <!-- <div class="col-sm-3"></div> -->
      </div>

      <!-- <div class="row">
        <div class="col-sm-12">
            <canvas style="align-content: center;" id="canvas"></canvas>
        </div>
    </div> -->
  
  
  </div>
  
  
  
  
  
  
  
  
  
  
  
    <style>

.img-responsive {
  display: inline-block;
}
  
  .selected{
     box-shadow:0px 12px 22px 1px rgb(8, 11, 199);
  }
  
  .ribbon{
    font-size:20px;
    position:relative;
    display:inline-block;
    margin:5em;
    text-align:center;
  }
  .text{
    display:inline-block;
    padding:0.5em 1em;
    min-width:20em;
    line-height:1.2em;
    background: #FFD72A;
    position:relative;
  }
  .ribbon:after,.ribbon:before,
  .text:before,.text:after,
  .bold:before{
    content:'';
    position:absolute;
    border-style:solid;
  }
  .ribbon:before{
    top:0.3em; left:0.2em;
    width:100%; height:100%;
    border:none;
    background:#EBECED;
    z-index:-2;
  }
  .text:before{
    bottom:100%; left:0;
    border-width: .5em .7em 0 0;
    border-color: transparent #FC9544 transparent transparent;
  }
  .text:after{
    top:100%; right:0;
    border-width: .5em 2em 0 0;
    border-color: #FC9544 transparent transparent transparent;
  }
  .ribbon:after, .bold:before{
    top:0.5em;right:-2em;
    border-width: 1.1em 1em 1.1em 3em;
    border-color: #FECC30 transparent #FECC30 #FECC30;
    z-index:-1;
  }
  .bold:before{
    border-color: #EBECED transparent #EBECED #EBECED;
    top:0.7em;
    right:-2.3em;
  }
  
  .fieldset {
    position: relative;
    border: 1px solid #ddd;
    padding: 10px;
  }
  
  .fieldset h1 {
    position: absolute;
    top: 0;
    font-size: 18px;
    line-height: 1;
    margin: -9px 0 0; /* half of font-size */
    background: #fff;
    padding: 0 3px;
  }
    </style>