import { Component, OnInit } from '@angular/core';
import { ImageCroppedEvent } from 'ngx-image-cropper'

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.css']
})
export class BannerComponent implements OnInit {

  imageChangedEvent: any = '';
  croppedImage: any = '';
  selectedImg: any = '';

  constructor() { }

  ngOnInit(): void {
  }

  onSubmit(userForm) {
    //console.log(userForm.form.value); 
    this.createBanner(userForm.form.value)
    var downloadBtn = <HTMLButtonElement>document.getElementById("download");
    //downloadBtn.disabled = false;
  }

  createBanner(userData) {
    var canvas = <HTMLCanvasElement>document.getElementById("canvas");
    var ctx = canvas?.getContext("2d");

    var background = new Image();
    var img2 = new Image();
    //img2 = this.croppedImage

    background.onload = function () {
      var self = this;
      ctx?.drawImage(background, 0, 0, 700, 800);


      //Code for circle creation
      //ctx?.beginPath();
      //ctx?.arc(100, 75, 50, 0, 2 * Math.PI);
      //var pat = ctx.createPattern(img2, "no-repeat");
      //ctx.fillStyle = pat;
      //ctx?.stroke();
      //ctx?.fill();
      //ctx?.clip();


      //ctx?.drawImage(img2, 300, 270, 100, 100);
      ctx?.drawImage(img2, 180, 170, 300, 330);


      //ctx?.drawImage(img2, 300, 250, 100, 100, 450 , 300, 100,100);
      ctx.font = `bold 20px Times New Roman`;

      var drNamestr = userData.drName;
      if (drNamestr.length > 10) {
        ctx.fillStyle = "#8B0000";
        ctx?.fillText(userData.drName, 260 - 10, 520);
      }
      else if (drNamestr.length > 20) {
        ctx.fillStyle = "#8B0000";
        ctx?.fillText(userData.drName, 260 - 30, 520);
      }
      else {
        ctx.fillStyle = "#8B0000";
        ctx?.fillText(userData.drName, 260, 520);
      }

      //ctx?.fillText(userData.drName, 290, 530);
      //ctx?.fillText(userData.clinicName, 450, 310);
      //ctx?.fillText(userData.place, 290, 550);
      //ctx?.fillText(userData.mobile, 450, 335);

      var placeStr = userData.place;
      if (placeStr.length > 10) {
        ctx.fillStyle = "#8B0000";
        ctx?.fillText(userData.place, 260 - 10, 550);
      }
      else if (placeStr.length > 20) {
        ctx.fillStyle = "#8B0000";
        ctx?.fillText(userData.place, 260 - 30, 550);
      }
      else {
        ctx.fillStyle = "#8B0000";
        ctx?.fillText(userData.place, 260, 550);
      }

    }

    this.selectedImg = "canvas5";
    var imgPath = "../assets/images/" + this.selectedImg + ".jpg"

    //background.src = "../assets/images/canvas.jpg";
    background.src = imgPath;
    //img2.src = "../assets/images/TowerCheck.jpg";
    //img2.className ="img-circle";
    img2.src = this.croppedImage;
    this.croppedImage.class = "img-circle";

    //img2.class = "border-radius: 50%;"

  }


  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }
  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    var lblPreview = <HTMLLabelElement>document.getElementById("lblPreview")
    lblPreview.textContent = "Image Preview";
  }
  imageLoaded() {
    // show cropper
  }
  cropperReady() {
    // cropper ready
  }
  loadImageFailed() {
    // show message

  }

  download() {
    this.selectedImg = "canvas5";
    var canvas = <HTMLCanvasElement>document.getElementById("canvas");
    var image = canvas.toDataURL("image/png").replace("image/png", "image/octet-stream");
    var link = document.createElement('a');
    link.download = "PosterImage.png";
    link.href = image;
    link.click();
  }l

  onImgSelected(imgId: any) {
    this.clearImgSelection();

    this.selectedImg = imgId;
    var imgElement = <HTMLImageElement>document.getElementById(imgId);
    imgElement.classList.add('selected');
  }

  clearImgSelection() {
    // var imgElement1 = <HTMLImageElement>document.getElementById('canvas1');
    // var imgElement2 = <HTMLImageElement>document.getElementById('canvas2');
    // var imgElement3 = <HTMLImageElement>document.getElementById('canvas3');
    // var imgElement4 = <HTMLImageElement>document.getElementById('canvas4');

    // imgElement1.classList.remove('selected');
    // imgElement2.classList.remove('selected');
    // imgElement3.classList.remove('selected');
    // imgElement4.classList.remove('selected');
  }
}
